<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
        <div class="row align-items-center">
            <div class="col-12 col-md-4 mb-0 mb-md-0 text-center text-md-start"> <!-- Center text on small screens -->
                <div class="page-header">
                    <h4 class="page-title">Department List</h4>
                </div>
            </div>
            <div class="col-lg-5 ms-auto">
                <div class="d-md-flex d-grid gap-3 justify-content-md-end">
                    <router-link :to="{name:'departmentdetails',params:{'dpid':''}}" type="button" class="btn btn-gradient-primary btn-fw">Add Department</router-link>
                </div>
            </div>
        </div>
      </br>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
            <div class="row">
                <div class="col-lg-4 mb-lg-0 mb-2">
                   <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchdepartment" id="searchdepartment" autocomplete="off" v-on:keyup="getDepartmentList(1)">
                </div>
                <div class="col-lg-6"></div>
                <div class="col-lg-2 mb-lg-0 mb-2">
                    <multiselect
                        v-model="filter_status"
                        :options="status_list"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="true" placeholder=""
                        :preselect-first="true"
                        :showLabels="false"
                        :allowEmpty="false"
                        @input=getDepartmentList(1)
                    /></multiselect>
                </div>
            </div>
              <br>
              <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Department Number</th>
                        <th>Department Name</th>
                        <th>Department Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(department,i) in departments" :key="i">
                        <td>{{department.id}}</td>
                        <td>{{department.department_name}}</td>
                        <td>
                          <label v-bind:class="{'badge badge-success': department.department_status === 'Active', 'badge badge-danger': department.department_status === 'InActive' || department.department_status === 'Inactive'}">{{department.department_status}}</label>
                        </td>
                        <td>
                            <router-link :to="{name:'departmentdetails',params:{'dpid':department.id}}">
                            <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-eye"></i></button>
                            </router-link>
                            &nbsp
                            <button type="button" @click="deleteDepartment(department.id, department.department_name)" class="btn btn-outline-secondary btn-rounded btn-icon"><i class="mdi mdi-delete"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
              </div>
              <div v-if="is_API_call_running" class="text-center mt-5">
                <b-spinner label=""></b-spinner>
              </div>
              <div class="row" v-if="!is_API_call_running && departments.length == 0" >
                  <div class="col-lg-12 text-center">
                    <br/><br/><br/>
                    <small class="text-muted">{{ errorMessage }}</small>
                    <br/><br/><br/>
                  </div>
              </div>
              <div class="b-pagination-outer" v-if="listPages > 1">
                <ul id="border-pagination">
                  <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                  <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getDepartmentList(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                  <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions } from "vuex";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import string from "../../constant/strings.js"
import Swal from 'sweetalert2'

export default {
  name:'department',
  title: string.PAGE_TITLE_DEPARTMENT_LIST,
  components: {
      NavBar,
Footer,
      SideBar,
      Multiselect
  },
  data() {
    return {
      departments:[],
      moment: moment,
      is_API_call_running:false,
      listPages:0,
      activePage:1,
      searchtext:'',
      myTimer:null,
      errorMessage: 'No Data Found.',
      status_list:['All','Active', 'Inactive'],
      filter_status: "All",
    }
  },
  mounted() {
    this.getDepartmentList(this.activePage);
  },
  methods:{
    ...mapActions("hospital",["getDepartmentListData", "deleteDepartmentData"]),

    getDepartmentList(page){
        this.activePage = page;
        clearTimeout(this.myTimer)
        this.myTimer = setTimeout(() => {
            this.is_API_call_running = true;
                  this.departments = [];
                  var bodyFormData = new FormData();
                  bodyFormData.append('filter_value', this.searchtext);
                  bodyFormData.append('filter_status', '');
                  bodyFormData.append('page', page);
                  bodyFormData.append('filter_status', this.filter_status);
                  this.getDepartmentListData(bodyFormData).then((response) => {
                    this.is_API_call_running = false;
                    if (response.response_code == 200) {
                      this.departments = response.data;
                      this.listPages = response.no_of_pages;
                    }else{
                      this.errorMessage = response.message;
                    }
                  });
        }, 500)
    },

    deleteDepartment(id, department_name, index) {
        Swal.fire({
          title: string.LABEL_ARE_YOU_SURE,
          text: string.ALERT_MESSAGE_DELETE_PRE + department_name + string.DEPARTMENT,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            bodyFormData.append('department_id', id);
            this.deleteDepartmentData(bodyFormData).then((response) => {
                if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    for (let i = 0; i <= this.departments.length;i++){
                        if(id == this.departments[i].id) {
                            this.departments.splice(i, 1);
                            break;
                        }
                    }
                } else{
                    this.$toasted.error(response.message, {duration: 2000,});
                }
            });
          }
        });
    },

    previewClick(){
              this.activePage--;
              if(this.activePage >= 1){
                this.getDepartmentList(this.activePage);
              }else{
                this.activePage = 1;
              }
            },

    nextClick(){
        this.activePage++;
        if(this.activePage <= this.listPages){
           this.getDepartmentList(this.activePage);
        }else{
           this.activePage = this.listPages;
        }
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
